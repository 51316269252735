<template>
     <main>
       <div class="container">
           <div class="row mt-4">
               <div class="col-lg-8 offset-lg-2">
                   <div class="card shadow-xs border-0"><router-view></router-view></div>
               </div>
           </div>
       </div>
     </main>
</template>